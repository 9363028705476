<template>
  <div>
    <header class="bg-primary">
      <div class="container">
        <div class="d-flex py-2">
          <a class="logo_govgr logo_small" href="/"></a>
          <div class="gov-logo-seperator pl-2"></div>
          <div class="d-md-flex">
            <div class="service-name text-white ml-4">Πορεία Δικών</div>
            <div class="align-self-center text-caption white--text ml-4">
              (H υπηρεσία είναι διαθέσιμη προσωρινά μόνο για το Πρωτοδικείο Αθηνών)
            </div>
          </div>

        </div>
      </div>
      <div class="borderHeader"></div>
    </header>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo_govgr.logo_small {
  width: 160px;
  height: 60px;
}

header .borderHeader {
  border: 0;
  height: 5px;
  background: linear-gradient(358.5deg, #00aeef 0.09%, #134a86);
}

header .gov-logo-seperator {
  border-right: 1px solid #10619f;
}

header .service-name {
  font-size: 25px;
  line-height: 50px;
}
</style>