<template>
  <div class="footer lightgrey pb-10 mt-7">
    <div class="top-border"></div>
    <div class="d-flex flex-column flex-md-row align-center justify-content-around mx-5">
      <div class="d-flex flex-column">
        <a href="https://dilosi.services.gov.gr/terms" class="my-3">
          Όροι και Προϋποθέσεις
        </a>
        <div>
          Υλοποίηση για το
          <a href="https://mindigital.gr/">Υπουργείο Ψηφιακής Διακυβέρνησης</a>
        </div>
      </div>
      <img class="logo ma-5" src=".\..\assets\gov_logo.png" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.top-border {
  height: 3px;
  background-color: var(--secondary);
}

.logo {
  width: 250px;
  height: auto;
}
</style>