<template>
  <div class="component-wrapper d-flex flex-column align-center py-6">
    <div class="text-h5 font-weight-medium text-center">
      Κατάσταση εκδίκασης υποθέσεων
    </div>

    <v-card class="d-flex flex-column flex-md-row pa-2 my-6 mx-3 lightgrey">
      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Ημερομηνία
        </div>
        <div class="text-bodyy-1 font-weight-bold">
          {{ formattedDate }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Δικαστήριο
        </div>
        <div class="text-bodyy-1 font-weight-bold">
          {{ courtName }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Διαδικασία
        </div>
        <div class="text-bodyy-1 font-weight-bold">
          {{ type == 1 ? "Πολιτική" : "Ποινική" }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">Είδος</div>
        <div class="text-bodyy-1 font-weight-bold">
          {{ kindName }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">Κτήριο</div>
        <div class="text-bodyy-1 font-weight-bold">
          {{ building }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Αίθουσα
        </div>
        <div class="text-bodyy-1 font-weight-bold">
          {{ room }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Πινάκιο
        </div>
        <div class="text-bodyy-1 font-weight-bold">
          {{ code }}
        </div>
      </div>
    </v-card>

    <div class="d-flex flex-column">
      <div class="flex-grow-1 d-flex flex-column flex-md-row align-center">
        <div class="d-flex align-center">
          <v-btn
            small
            fab
            dark
            depressed
            class="ma-4"
            color="blue lighten-1"
            @click="refreshData"
          >
            <v-progress-circular
              indeterminate
              size="20"
              color="white"
              v-if="loader"
            ></v-progress-circular>

            <v-icon v-if="!loader" color="white"> mdi-refresh </v-icon>
          </v-btn>

          <div class="d-flex flex-column align-start">
            <div class="text-subtitle-1">
              Ανανέωση πληροφοριών

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    right
                    color="grey darken-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>
                  Πατήστε το σύμβολο ανανέωσης για να εμφανιστούν τα πιο
                  πρόσφατα στοιχεία
                </span>
              </v-tooltip>
            </div>

            <div class="text-caption">
              (Τελευταία ενημέρωση: {{ latestRefresh }})
            </div>
          </div>
        </div>

        <div
          v-if="inBreak"
          id="break-annotation"
          class="text-body lightred--text font-weight-bold ml-md-auto mr-4 mb-2"
        >
          Διάλειμμα
        </div>
      </div>

      <template>
        <v-data-table
          hide-default-footer
          disable-pagination
          loader-height="2px"
          class="elevation-2"
          noResultsText="Δεν βρέθηκαν αποτελέσματα"
          noDataText="Δεν βρέθηκαν αποτελέσματα"
          loadingText="Παρακαλώ περιμένετε..."
          :headers="type == 1 ? politikoHeaders : poinikoHeaders"
          :items="docketEntries"
          :item-class="getRowClass"
          :loading="loader"
          :no-data-text="'Δεν υπάρχουν πληροφορίες'"
          :header-props="headerProps"
        >
          <template #[`item.creationTimestamp`]="{ item }">
            <div>
              {{ getFormattedTime(item.creationTimestamp) }}
            </div>
          </template>

          <template #[`item.state`]="{ item }">
            <div :class="getStateColor(item.state)">
              {{ item.state }}
            </div>
          </template>
        </v-data-table>
      </template>

      <div class="d-flex flex-column flex-sm-row align-center mt-4 mt-md-6">
        <div class="d-flex align-center mr-auto mb-3 mb-sm-0">
          <v-tooltip top v-model="showCopyTooltip" :open-on-hover="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                fab
                small
                depressed
                v-bind="attrs"
                v-on="on"
                color="white"
                class="mx-3 blue-grey lighten-3"
                @click="copyLink"
              >
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
            </template>
            <span> Ο σύνδεσμος αντιγράφηκε </span>
          </v-tooltip>

          <div class="text-caption">Αντιγραφή συνδέσμου σελίδας</div>
        </div>

        <!-- <v-btn
          text
          small
          outlined
          class="d-block mt-1 mr-1 mb-2 d-sm-inline-block mb-md-0 mr-md-3"
          @click="
            toggleDialog({
              open: true,
              component: 'docket-register-dialog',
              width: '500px',
              board: {
                code,
                docketId: docketsId,
                docketTapId: docketsTapPK,
              },
            })
          "
        >
          Εγγραφη
          <v-icon small class="ml-2" color="teal lighten-2">
            mdi-checkbox-marked-outline
          </v-icon>
        </v-btn>

        <v-btn
          text
          small
          outlined
          class="d-block mt-1 mr-1 mb-2 d-sm-inline-block mb-md-0"
          @click="
            toggleDialog({
              open: true,
              component: 'docket-unregister-dialog',
              width: '500px',
              board: {
                code,
                docketId: docketsId,
                docketTapId: docketsTapPK,
              },
            })
          "
        >
          Απεγγραφη
          <v-icon small class="ml-2" color="red lighten-2">
            mdi-close-box-outline
          </v-icon>
        </v-btn> -->
      </div>

      <div class="d-flex justify-content-around mt-6">
        <v-btn outlined class="mr-6" color="darkblue" @click="goBack()">
          <v-icon left color="darkblue">mdi-menu-left</v-icon>
          πισω
        </v-btn>

        <v-btn class="primary" @click="$router.push('/search-trial')">
          Νεα Αναζητηση
          <v-icon right color="white">mdi-menu-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
  data: function () {
    return {
      headerProps: {
        sortByText: "Ταξινόμηση ανά",
      },
      politikoHeaders: [
        {
          text: "A/A",
          value: "caseSerialNumber",
          class: "primary white--text rounded-tl",
        },
        {
          text: "ΕΑΚ",
          value: "eak",
          class: "primary white--text",
        },
        {
          text: "ΓΑΚ",
          value: "caseCode",
          class: "primary white--text",
        },
        {
          text: "ΕΝΑΓΩΝ",
          value: "claimantNameCencored",
          class: "primary white--text",
        },
        {
          text: "ΕΝΑΓΟΜΕΝΟΣ",
          value: "defendantNameCensored",
          class: "primary white--text",
        },
        {
          text: "ΩΡΑ",
          value: "creationTimestamp",
          class: "primary white--text",
        },
        {
          text: "ΚΑΤΑΣΤΑΣΗ",
          value: "state",
          class: "primary white--text",
        },
        {
          text: "ΠΑΡΑΤΗΡΗΣΕΙΣ",
          value: "comments",
          class: "primary white--text rounded-tr",
          sortable: false,
        },
      ],
      poinikoHeaders: [
        {
          text: "A/A",
          value: "caseSerialNumber",
          class: "primary white--text rounded-tl",
        },
        {
          text: "ΓΑΔ",
          value: "caseCode",
          class: "primary white--text",
        },
        {
          text: "ΑΒΜ",
          value: "abm",
          class: "primary white--text",
        },
        {
          text: "ΚΑΤΗΓΟΡΟΥΜΕΝΟΣ",
          value: "defendantNameCensored",
          class: "primary white--text",
        },
        {
          text: "ΩΡΑ",
          value: "creationTimestamp",
          class: "primary white--text",
        },
        {
          text: "ΚΑΤΑΣΤΑΣΗ",
          value: "state",
          class: "primary white--text",
        },
        {
          text: "ΠΑΡΑΤΗΡΗΣΕΙΣ",
          value: "comments",
          class: "primary white--text rounded-tr",
          sortable: false,
        },
      ],
      showRefreshTooltip: false,
      showCopyTooltip: false,
      loader: false,
      latestRefresh: "",
      timestamp: this.$route.query.timestamp,
      courtId: this.$route.query.courtId,
      courtTapId: this.$route.query.courtTapId,
      courtName: this.$route.query.courtName,
      type: this.$route.query.type,
      kindId: this.$route.query.kindId,
      kindName: this.$route.query.kindName,
      building: this.$route.query.building,
      room: this.$route.query.room,
      code: this.$route.query.code,
      docketsTapId: this.$route.query.docketsTapId,
      docketsId: this.$route.query.docketsId,
    };
  },
  computed: {
    ...mapState({
      docketEntries: (state) => state.docketEntries.data,
      inBreak: (state) => state.docketEntries.inBreak,
    }),
    formattedDate() {
      return moment.unix(this.timestamp / 1000).format("DD/MM/y");
    },
  },
  methods: {
    ...mapActions(["getDocketEntries", "getAnnotation"]),
    ...mapMutations(["resetDocketEntries", "setDocketEntries", "toggleDialog"]),
    async refreshData() {
      this.loader = true;

      // refresh data
      await this.getDocketEntries({
        courtId: this.courtId,
        docketsTapId: this.docketsTapId,
        docketsId: this.docketsId,
      });

      this.loader = false;

      // set refresh time
      this.latestRefresh = moment().format("HH:mm:ss");
    },
    getStateColor(state) {
      let styleClass = "white--text py-1 px-2 ";
      if (state == "Αναβολή") return styleClass + "amber darken-2";
      else if (state == "Διακοπή") return styleClass + "red lighten-1";
      else if (state == "Συζητήθηκε") return styleClass + "light-blue darken-2";
    },
    getFormattedTime(dateTimestamp) {
      return dateTimestamp != "-"
        ? moment(dateTimestamp).format("HH:mm:ss")
        : "-";
    },
    getRowClass(item) {
      if (
        item.state == "Επανέναρξη Υπόθεσης" ||
        item.state == "Έναρξη Υπόθεσης"
      ) {
        return "lightgreen font-weight-bold";
      } else if (item.state == "Λήξη Υπόθεσης") {
        return "deep-orange lighten-3";
      } else if (item.state == "Διακοπή Υπόθεσης") {
        return "yellow lighten-1";
      }
    },
    copyLink() {
      this.showCopyTooltip = true;

      this.$copyText(window.location.href);
      // this.$copyText(
      //   "https://dikes.moj.gov.gr" + this.$router.history.current.path
      // );

      setTimeout(() => (this.showCopyTooltip = false), 2500);
    },
    goBack() {
      this.resetDocketEntries();
      this.$router.go(-1);
    },
  },
  async created() {
    // set moment to use greek
    moment.updateLocale("el", {
      meridiem: function (hour) {
        if (hour < 12) {
          return "π.μ.";
        } else {
          return "μ.μ.";
        }
      },
    });
    // get data for table
    await this.refreshData();
  },
  beforeDestroy() {
    this.setDocketEntries([]);
  },
};
</script>

<style lang="scss" scoped>
*:focus {
  outline: 0 !important;
}
</style>