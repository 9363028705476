<template>
  <v-card>
    <v-card-title>
      Απεγγραφή από το Πινάκιο
      <span class="ml-2 font-weight-bold">"{{ dialog.board.code }}"</span>
      <v-btn
        icon
        color="blue-grey darken-3"
        class="ml-auto"
        @click="toggleDialog({ open: false })"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card-title>

    <v-stepper v-model="step" class="elevation-0">
      <v-stepper-header class="elevation-0">
        <v-divider></v-divider>

        <v-stepper-step :complete="step > 1" step="1">
          Συμπλήρωση Κινητού
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          OTP Κωδικός
        </v-stepper-step>

        <v-divider></v-divider>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="pt-0" step="1">
          <v-card-text class="pt-0">
            Συμπληρώστε το κινητό σας για να σας αποσταλεί ο OTP κωδικός και να
            ολοκληρωθεί η απεγγραφή
          </v-card-text>

          <v-card-actions class="d-flex flex-column flex-sm-row">
            <v-text-field
              :style="{ minWidth: '100px' }"
              type="number"
              label="Κινητό"
              class="mr-6"
              v-model="phone"
            ></v-text-field>

            <v-btn color="primary" :disabled="!phone" @click="step = 2">
              αποστολη
            </v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content class="pt-0" step="2">
          <v-card-text class="pt-0">
            Συμπληρώστε τον OTP κωδικό που στάλθηκε στο κινητό σας
          </v-card-text>

          <v-card-actions class="d-flex flex-column">
            <v-otp-input
              :style="{ maxWidth: '250px' }"
              length="4"
              type="number"
              class="mb-8"
              v-model="digits"
            ></v-otp-input>

            <div>
              <v-btn outlined class="mr-6" color="darkblue" @click="step = 1">
                <v-icon left color="darkblue">mdi-menu-left</v-icon>
                πισω
              </v-btn>

              <v-btn
                color="primary"
                :disabled="!(digits.length === 4)"
                @click="submit"
              >
                Απεγγραφη
              </v-btn>
            </div>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      step: 1,
      phone: null,
      digits: "",
      successSnackbar: false,
    };
  },
  computed: {
    ...mapState({
      dialog: (state) => state.base.dialog,
    }),
  },
  methods: {
    ...mapMutations(["toggleDialog", "toggleSnackbar"]),
    submit() {
      this.toggleDialog({ open: false });
      this.toggleSnackbar({
        open: true,
        color: "success",
        text: "Η απεγγραφή ολοκληρώθηκε με επιτυχία",
      });
    },
  },
}
</script>


<style scoped>

</style>