import Vue from 'vue'
import Vuex from 'vuex'
import base from './base.js'
import search from './search.js'
import docketEntries from './docketEntries.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base,
    search,
    docketEntries
  }
})
