import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from './views/Landing.vue'
import SearchTrial from './views/SearchTrial.vue'
import Dockets from './views/Dockets.vue'
import DocketEntries from './views/DocketEntries.vue'
import MoreInfo from './views/MoreInfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Landing
  },
  {
    path: '/more-info',
    component: MoreInfo
  },
  {
    path: '/search-trial',
    component: SearchTrial
  },
  {
    path: '/dockets',
    component: Dockets
  },
  {
    path: '/docket-entries',
    component: DocketEntries,
  },
  {
    path: "*",
    redirect: "/"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
