<template>
  <v-app>
    <Header />

    <v-main>
      <router-view></router-view>
    </v-main>

    <Footer />

    <v-snackbar v-model="snackbar.open" :color="snackbar.color">
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>
        <v-btn
          icon
          class="ml-auto"
          @click="
            toggleSnackbar({
              open: false,
            })
          "
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>

    <v-dialog
      persistent
      overlay-opacity="0.7"
      v-model="dialog.open"
      :max-width="dialog.width"
    >
      <component v-if="dialog.open" :is="dialog.component" />
    </v-dialog>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import DocketRegisterDialog from "@/components/dialogs/DocketRegisterDialog.vue";
import DocketUnregisterDialog from "@/components/dialogs/DocketUnregisterDialog.vue";

import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Header,
    Footer,
    DocketRegisterDialog,
    DocketUnregisterDialog
  },
  computed: {
    ...mapState({
      snackbar: (state) => state.base.snackbar,
      dialog: (state) => state.base.dialog,
    }),
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
  },
};
</script>

<style lang="scss">
@import "./govgr_bootstrap.min.css";

.v-main__wrap {
  display: flex;
}

.component-wrapper {
  flex-grow: 1;
}

// Global styles
.v-data-table-header-mobile tr th {
  border-bottom: 0px !important;
}

.v-data-table__mobile-row:last-of-type {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4) !important;
  // padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.v-data-table__mobile-row {
  min-height: 24px !important;
}
</style>
