import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#10619f',
        darkblue: '#1E2D56',
        lightgrey: '#ECEFF1',
        lightgreen: '#65FC03',
        lightred: '#FFC300',
      },
    },
  },
});