<template>
  <div class="component-wrapper d-flex flex-column align-center py-6">
    <div class="text-h5 font-weight-medium">Συνεδριάσεις</div>

    <v-card class="d-flex flex-column flex-md-row pa-2 my-6 mx-3 lightgrey">
      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Ημερομηνία
        </div>
        <div class="text-body-1 font-weight-bold">
          {{ formattedDate }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Δικαστήριο
        </div>
        <div class="text-body-1 font-weight-bold">
          {{ courtName }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Διαδικασία
        </div>
        <div class="text-body-1 font-weight-bold">
          {{ type == 1 ? "Πολιτική" : "Ποινική" }}
        </div>
      </div>

      <div class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">Είδος</div>
        <div class="text-body-1 font-weight-bold">
          {{ kindName }}
        </div>
      </div>

      <div v-if="building" class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">Κτήριο</div>
        <div class="text-body-1 font-weight-bold">
          {{ building }}
        </div>
      </div>

      <div v-if="room" class="d-flex d-md-block mx-5">
        <div style="width: 100px" class="text-caption mr-4 mr-md-0">
          Αίθουσα
        </div>
        <div class="text-body-1 font-weight-bold">
          {{ room }}
        </div>
      </div>
    </v-card>

    <template>
      <v-data-table
        disable-pagination
        hide-default-footer
        class="elevation-2"
        noResultsText="Δεν βρέθηκαν αποτελέσματα"
        noDataText="Δεν βρέθηκαν αποτελέσματα"
        loadingText="Παρακαλώ περιμένετε..."
        :loading="loader"
        :headers="headers"
        :items="dockets"
        :header-props="headerProps"
      >
        <template v-slot:[`header.options`]>
          <div class="text-center">Επιλογές</div>
        </template>

        <template #[`item.options`]="{ item }">
          <v-btn
            text
            small
            outlined
            class="d-block mt-1 mb-2 d-sm-inline-block"
            @click="
              $router.push({
                path: 'docket-entries',
                query: {
                  courtId,
                  courtName,
                  timestamp,
                  kindId,
                  kindName,
                  typeId: item.courtTypeId,
                  building: item.building,
                  room: item.room,
                  code: item.code,
                  docketsTapId: item.docketsPK.tapId,
                  docketsId: item.docketsPK.id,
                },
              })
            "
          >
            Προβολη
            <v-icon small class="ml-2" color="blue-grey darken-2">
              mdi-open-in-app
            </v-icon>
          </v-btn>

          <!-- <v-btn
            text
            small
            outlined
            class="d-block mt-1 mr-1 mb-2 d-sm-inline-block mb-md-0 mr-md-3"
            @click="
              toggleDialog({
                open: true,
                component: 'docket-register-dialog',
                width: '500px',
                board: {
                  code: item.code,
                  docketId: item.docketsPK.id,
                  docketTapId: item.docketsPK.tapId,
                },
              })
            "
          >
            Εγγραφη
            <v-icon small class="ml-2" color="teal lighten-2">
              mdi-checkbox-marked-outline
            </v-icon>
          </v-btn>

          <v-btn
            text
            small
            outlined
            class="d-block mt-1 mr-1 mb-2 d-sm-inline-block mb-md-0"
            @click="
              toggleDialog({
                open: true,
                component: 'docket-unregister-dialog',
                width: '500px',
                board: {
                  code: item.code,
                  docketId: item.docketsPK.id,
                  docketTapId: item.docketsPK.tapId,
                },
              })
            "
          >
            Απεγγραφη
            <v-icon small class="ml-2" color="red lighten-2">
              mdi-close-box-outline
            </v-icon>
          </v-btn> -->
        </template>
      </v-data-table>
    </template>

    <v-btn outlined class="mt-6" color="darkblue" @click="$router.go(-1)">
      <v-icon left color="darkblue">mdi-menu-left</v-icon>
      πισω
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      loader: false,
      headerProps: {
        sortByText: "Ταξινόμηση ανά",
      },
      headers: [
        {
          text: "Κωδικός Πινακίου",
          value: "code",
          class: "primary white--text rounded-tl",
        },
        {
          text: "Κτήριο",
          value: "building",
          class: "primary white--text",
        },
        {
          text: "Αίθουσα",
          value: "room",
          class: "primary white--text",
        },
        {
          text: "Επιλογές",
          value: "options",
          class: "primary white--text rounded-tr",
          sortable: false,
        },
      ],
      timestamp: this.$route.query.timestamp,
      courtId: this.$route.query.courtId,
      courtTapId: this.$route.query.courtTapId,
      courtName: this.$route.query.courtName,
      type: this.$route.query.type,
      kindId: this.$route.query.kindId,
      kindName: this.$route.query.kindName,
      building: this.$route.query.building,
      room: this.$route.query.room,
    };
  },
  computed: {
    ...mapState({
      dockets: (state) => state.search.dockets,
    }),
    formattedDate() {
      return moment.unix(this.timestamp / 1000).format("DD/MM/y");
    },
  },
  methods: {
    ...mapActions(["getDockets"]),
    ...mapMutations(["toggleDialog"]),
  },
  async mounted() {
    // console.log(this.$route.query);

    this.loader = true;

    // get data for table
    await this.getDockets({
      timestamp: this.timestamp,
      court: {
        courtId: this.courtId,
        tapId: this.courtTapId,
      },
      typeId: this.type,
      kind: { id: this.kindId },
      building: this.building,
      room: this.room,
    });

    this.loader = false;
  },
};
</script>

<style lang="scss" scoped>
</style>