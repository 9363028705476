<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <div class="text-h6 text-center mb-6 mx-6 mx-md-0">
      Επεξήγηση καταστάσεων μιας υπόθεσης
    </div>

    <div class="text-body mb-10 mx-6 mx-md-0">
      Οι υποθέσεις ενός πινακίου έχουν καταστάσεις ανάλογα με το στάδιο
      εκδίκασής τους. Οι καταστάσεις αυτές επεξηγούνται στον παρακάτω πίνακα.
    </div>

    <table>
      <tr>
        <th>Κατάσταση</th>
        <th>Χρώμα</th>
        <th>Επεξήγηση</th>
      </tr>
      <tr>
        <td>Έναρξη Υπόθεσης</td>
        <td class="lightgreen color" width="50px"></td>
        <td>Η υπόθεση έχει αρχίσει να εκδικάζεται.</td>
      </tr>
      <tr>
        <td>Λήξη Υπόθεσης</td>
        <td class="deep-orange lighten-3" width="50px"></td>
        <td>Η υπόθεση έχει λήξει.</td>
      </tr>
      <tr>
        <td>Διακοπή υπόθεσης</td>
        <td class="yellow lighten-1" width="50px"></td>
        <td>Η υπόθεση έχει διακοπεί.</td>
      </tr>
      <tr>
        <td>Επανέναρξη υπόθεσης</td>
        <td class="lightgreen color" width="50px"></td>
        <td>Η υπόθεση άρχισε να εκδικάζεται μετά από διακοπή.</td>
      </tr>
      <tr>
        <td>Εν Αναμονή</td>
        <td></td>
        <td>Η υπόθεση δεν έχει αρχίσει να εκδικάζεται ακόμα.</td>
      </tr>
    </table>

    <div class="text-body mt-10 mb-6 mx-6 mx-md-0">
      Ένα στιγμιότυπο από την εκδίκαση ορισμένων υποθέσεων παρουσιάζεται
      παρακάτω. Εκτός από την Κατάσταση, περιλαμβάνονται και πληροφορίες για την
      ώρα και στοιχεία που προσδιορίζουν μοναδικά μία υπόθεση όπως ΓΑΔ, ΑΒΜ,
      κτλ. Σε περίπτωση διαλείμματος, θα εμφανίζεται επίσης η λέξη
      <strong>Διάλειμμα</strong>
    </div>

    <img v-if="isMobile" src="/assets/info-mobile.png" alt="παράδειγμα πίνακα" />
    <img v-else src="/assets/info-desktop.png" alt="παράδειγμα πίνακα" />

    <v-btn outlined width="100px" color="darkblue" @click="$router.go(-1)">
      <v-icon left color="darkblue">mdi-menu-left</v-icon>
      πισω
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: window.innerWidth < 600
    }
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 600px) {
  .component-wrapper {
    padding: 48px 15%;
  }
}

@media only screen and (max-width: 600px) {
  .component-wrapper {
    padding: 48px 0px;
  }
  table {
    width: 100vw;
  }
}

th {
  background: #e4e4e4;
}

th,
td {
  padding: 10px 20px;
  border: 1px solid rgb(190, 190, 190);
}

.color {
  border: 1px;
}

img {
  transform: scale(0.8);
  max-width: 100%;
}
</style>