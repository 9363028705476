import axios from "axios"

export default {
    state: {
        data: [],
        inBreak: false,
    },
    mutations: {
        setDocketEntries(state, payload) {
            state.data = payload
        },
        resetDocketEntries(state) {
            state.data = []
        },
        setInBreak(state, inBreak) {
            state.inBreak = inBreak
        }
    },
    actions: {
        async getDocketEntries({ commit }, payload) {
            try {
                // get docket entries
                const entriesRes = await axios.get(`/docketentries/search/${payload.courtId}/${payload.docketsTapId}/${payload.docketsId}`)

                // remove duplicate docket entries
                for (let i = 0; i < entriesRes.data.length; i++) {
                    let curId = entriesRes.data[i].uid

                    for (let l = 0; l < i; l++) {
                        if (entriesRes.data[l].uid == curId) {
                            entriesRes.data.splice(l, 1);
                            i--;
                        }
                    }
                }

                // search for break
                const annotationRes = await axios.get(`/annotations/search/${payload.courtId}/${payload.docketsTapId}/${payload.docketsId}`)
                console.log(annotationRes)
                

                let inBreak = false;
                let breakAnTime = 0;
                for (let g = 0; g < annotationRes.data.length; g++) {
                    if (annotationRes.data[g].type == 4 && annotationRes.data[g].creationTimestamp > breakAnTime) {
                        inBreak = true
                        breakAnTime = annotationRes.data[g].creationTimestamp
                    }

                    if (annotationRes.data[g].type == 5 && annotationRes.data[g].creationTimestamp > breakAnTime) {
                        inBreak = false
                        breakAnTime = annotationRes.data[g].creationTimestamp
                    }
                }
                commit("setInBreak", inBreak)

                // get annotations for each docket entry
                for (let j = 0; j < entriesRes.data.length; j++) {
                    let docketEntry = entriesRes.data[j];
                    if (docketEntry.joinedUid) {
                        docketEntry.annotation = await axios.get(`/annotations/${docketEntry.joinedUid}`);
                    }
                    else {
                        docketEntry.annotation = await axios.get(`/annotations/${docketEntry.uid}`);
                    }

                    // get latest annotation
                    if (docketEntry.annotation.data.length > 0) {
                        let latest = docketEntry.annotation.data[0].creationTimestamp
                        let index = 0;

                        let k = 0;
                        for (const annotation of docketEntry.annotation.data) {

                            if (annotation.type == 1) {
                                latest = annotation.creationTimestamp
                                index = k;
                                break;
                            }

                            if (annotation.creationTimestamp > latest || annotation.type == 2) {
                                latest = annotation.creationTimestamp
                                index = k;
                            }

                            k++;
                        }

                        docketEntry.annotation = docketEntry.annotation.data[index]
                    }
                    else {
                        docketEntry.annotation = docketEntry.annotation.data
                    }

                    // set type state and comments
                    docketEntry.creationTimestamp = docketEntry.annotation.creationTimestamp;

                    if (docketEntry.claimantName) {
                        docketEntry.claimantNameCencored = docketEntry.claimantName.slice(0, 1) + "**";
                    }

                    if (docketEntry.defendantName) {
                        docketEntry.defendantNameCensored =
                            docketEntry.defendantName.slice(0, 1) + "**";
                    }

                    if (docketEntry.annotation.type != undefined) {
                        if (docketEntry.annotation.type == 0) {
                            docketEntry.state = "Έναρξη Υπόθεσης";
                        } else if (docketEntry.annotation.type == 1) {
                            docketEntry.state = "Λήξη Υπόθεσης";
                        } else if (docketEntry.annotation.type == 2) {
                            docketEntry.state = "Διακοπή Υπόθεσης";
                        } else if (docketEntry.annotation.type == 3) {
                            docketEntry.state = "Επανέναρξη Υπόθεσης";
                        } else if (docketEntry.annotation.type == 4) {
                            docketEntry.state = "Έναρξη Διαλείμματος";
                        } else if (docketEntry.annotation.type == 5) {
                            docketEntry.state = "Λήξη Διαλείμματος";
                        }

                        if (docketEntry.annotation.caseEndType == 0) {
                            docketEntry.comments = "Αναβολή";
                        } else if (docketEntry.annotation.caseEndType == 1) {
                            docketEntry.comments = "Συζητείται";
                        } else if (docketEntry.annotation.caseEndType == 2) {
                            docketEntry.comments = "Βίαιη διακοπή";
                        } else if (docketEntry.annotation.caseEndType == 3) {
                            docketEntry.comments = "Ματαιούται";
                        } else if (docketEntry.annotation.caseEndType == 4) {
                            docketEntry.comments = "Συμβιβασμός";
                        } else if (docketEntry.annotation.caseEndType == 5) {
                            docketEntry.comments = "Παραίτηση δικηγόρου";
                        } else if (docketEntry.annotation.caseEndType == 6) {
                            docketEntry.comments = "Αναβολή με αντιδικία";
                        } else if (docketEntry.annotation.caseEndType == 7) {
                            docketEntry.comments = "Αναβολή συναινετική";
                        } else if (docketEntry.annotation.caseEndType == 8) {
                            docketEntry.comments = "Παραίτηση δικογράφου";
                        } else if (docketEntry.annotation.caseEndType == 9) {
                            docketEntry.comments = "Κατάργηση δίκης";
                        } else if (docketEntry.annotation.caseEndType == 10) {
                            docketEntry.comments = "Χωρίς εκφώνηση";
                        }
                    } else {
                        docketEntry.creationTimestamp = "-";
                        docketEntry.state = "Εν Αναμονή";
                    }
                }

                commit("setDocketEntries", entriesRes.data)
            } catch (error) {
                console.log(error.toJSON())

            }
        }
    }
}