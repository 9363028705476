export default {
    state: {
        snackbar: {
            open: false,
            text: null,
            color: null,
        },
        dialog: {
            open: false,
            component: null,
            width: null,
            boardCode: null,    // for docket register/unregister dialogs
        },
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },
        toggleDialog(state, dialog) {
            state.dialog = {
                ...state.dialog,
                ...dialog
            };
        },
    }
}