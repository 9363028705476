<template>
  <div class="component-wrapper d-flex flex-column align-center py-12">
    <div class="text-h6 mb-5">
      <v-icon left>mdi-magnify</v-icon> Αναζήτηση Δίκης
    </div>

    <v-form
      class="d-flex flex-column lightgrey rounded elevation-2 px-6 pt-6 pb-2"
      :style="{ width: $vuetify.breakpoint.xsOnly ? '300px' : '450px' }"
      @submit.prevent="submit"
    >
      <v-text-field
        dense
        required
        disabled
        label="Ημερομηνία *"
        class="mb-5"
        :value="formattedDate"
      ></v-text-field>

      <v-select
        dense
        required
        disabled
        return-object
        label="Δικαστήριο *"
        no-data-text="Δεν υπάρχουν αποτελέσματα"
        v-model="form.court"
        :item-text="(item) => item.city + ' - ' + item.name"
        :items="courts"
        :loading="courtsLoader"
        @change="onSelectCourt"
      ></v-select>

      <v-radio-group
        row
        dense
        required
        label="Διαδικασία *"
        :disabled="!form.date && !form.court"
        v-model="form.type"
        @change="onSelectType"
      >
        <v-radio value="1" label="Πολιτική"></v-radio>
        <v-radio value="2" label="Ποινική"></v-radio>
      </v-radio-group>

      <v-select
        dense
        required
        return-object
        label="Είδος *"
        item-text="name"
        item-value="id"
        no-data-text="Δεν υπάρχουν αποτελέσματα"
        :items="kinds"
        :loading="kindsLoader"
        :disabled="!form.type"
        v-model="form.kind"
        @change="onSelectKind"
      ></v-select>

      <v-select
        dense
        required
        label="Κτήριο"
        no-data-text="Δεν υπάρχουν αποτελέσματα"
        :items="buildings"
        :loading="buildingsLoader"
        :disabled="!form.kind"
        v-model="form.building"
        @change="onSelectBuilding"
      ></v-select>

      <v-select
        dense
        required
        label="Αίθουσα"
        no-data-text="Δεν υπάρχουν αποτελέσματα"
        :items="rooms"
        :loading="roomsLoader"
        :disabled="!form.building"
        v-model="form.room"
      ></v-select>

      <v-card-actions>
        <v-btn
          :disabled="!isValid"
          :loading="loader"
          class="primary btn-lg"
          type="submit"
        >
          Αναζητηση
          <v-icon right color="white">mdi-menu-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      courts: [],
      form: {
        timestamp: null,
        court: null,
        type: null,
        kind: null,
        building: null,
        room: null,
      },
      courtsLoader: false,
      kindsLoader: false,
      buildingsLoader: false,
      roomsLoader: false,
      loader: false,
    };
  },
  computed: {
    formattedDate() {
      return this.form.timestamp
        ? moment.unix(this.form.timestamp / 1000).format("DD/MM/y")
        : "";
    },
    ...mapState({
      kinds: (state) => state.search.kinds,
      buildings: (state) => state.search.buildings,
      rooms: (state) => state.search.rooms,
    }),
    isValid() {
      return this.form.court && this.form.type && this.form.kind;
    },
  },
  methods: {
    ...mapActions(["getKinds", "getBuildings", "getRooms"]),
    ...mapMutations(["setKinds", "setBuildings", "setRooms", "resetDockets"]),
    resetSearchArgs(args) {
      if (args.kind) {
        this.form.kind = null;
        this.setKinds([]);
      }
      if (args.building) {
        this.form.building = null;
        this.setBuildings([]);
      }
      if (args.room) {
        this.form.room = null;
        this.setRooms([]);
      }
    },
    async onSelectDate() {
      this.resetSearchArgs({
        kind: true,
        building: true,
        room: true,
      });
      if (this.form.court && this.form.type) {
        this.kindsLoader = true;
        await this.getKinds({
          timestamp: this.form.timestamp,
          court: this.form.court,
          typeId: this.form.type,
        });
        this.kindsLoader = false;
      }
    },
    async onSelectCourt() {
      this.resetSearchArgs({
        kind: true,
        building: true,
        room: true,
      });
      if (this.form.timestamp && this.form.type) {
        this.kindsLoader = true;
        await this.getKinds({
          timestamp: this.form.timestamp,
          court: this.form.court,
          typeId: this.form.type,
        });
        this.kindsLoader = false;
      }
    },
    async onSelectType() {
      this.resetSearchArgs({
        kind: true,
        building: true,
        room: true,
      });
      this.kindsLoader = true;
      await this.getKinds({
        timestamp: this.form.timestamp,
        court: this.form.court,
        typeId: this.form.type,
      });
      this.kindsLoader = false;
    },
    async onSelectKind() {
      this.resetSearchArgs({
        building: true,
        room: true,
      });
      this.buildingsLoader = true;
      await this.getBuildings({
        timestamp: this.form.timestamp,
        court: this.form.court,
        typeId: this.form.type,
        kind: this.form.kind,
      });
      this.buildingsLoader = false;
    },
    async onSelectBuilding(event) {
      this.resetSearchArgs({
        room: true,
      });
      this.roomsLoader = true;
      await this.getRooms({
        timestamp: this.form.timestamp,
        court: this.form.court,
        typeId: this.form.type,
        kind: this.form.kind,
        building: this.form.building,
      });
      this.roomsLoader = false;
    },
    async submit() {
      this.resetDockets([]);

      // change page
      this.$router.push({
        path: "/dockets",
        query: {
          timestamp: this.form.timestamp,
          courtId: this.form.court.courtId,
          courtTapId: this.form.court.tapId,
          courtName: this.form.court.name,
          type: this.form.type,
          kindId: this.form.kind.id,
          kindName: this.form.kind.name,
          building: this.form.building,
          room: this.form.room,
        },
      });
    },
  },
  async created() {
    this.form.timestamp = moment().valueOf();
    // this.form.timestamp = 1655328600000;

    this.courtsLoader = true;

    let res = await axios.get("/courthouses");
    this.courts = res.data;
    this.form.court = this.courts[0];

    this.courtsLoader = false;
  },
};
</script>

<style lang="scss" scoped>
</style>