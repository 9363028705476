import axios from "axios";

export default {
    state: {
        dockets: [],
        kinds: [],
        buildings: [],
        rooms: [],
    },
    mutations: {
        setDockets(state, payload) {
            state.dockets = payload
        },
        resetDockets(state) {
            state.dockets = []
        },
        setKinds(state, payload) {
            state.kinds = payload;
        },
        setBuildings(state, payload) {
            state.buildings = payload;
        },
        setRooms(state, payload) {
            state.rooms = payload;
        },
    },
    actions: {
        async getDockets({ commit }, data) {
            let dataUrl = `${data.court.courtId}/${data.court.tapId}/${data.timestamp}/${data.typeId}`

            if (data.kind)
                dataUrl += `/${data.kind.id}`
            if (data.building)
                dataUrl += `/${data.building}`
            if (data.room)
                dataUrl += `/${data.room}`

            try {
                const res = await axios.get(`/dockets/search/${dataUrl}`)

                commit('setDockets', res.data)

                return res
            } catch (er) {
                console.log(er)
            }
        },
        async getKinds({ state, commit, dispatch }, payload) {
            try {
                // get dockets 
                await dispatch('getDockets', payload)

                // filter kinds from dockets in string format
                const kindsListString = state.dockets.map(docket => {
                    return JSON.stringify({
                        id: docket.courtKindId,
                        name: docket.courtKindName
                    })
                })

                // create array of kinds objects from string format
                let kinds = [...new Set(kindsListString)];
                kinds = kinds.map(docket => {
                    return JSON.parse(docket);
                })

                commit("setKinds", kinds)
            } catch (e) {
                // TODO: error snackbar
                throw e;
            }
        },
        async getBuildings({ state, commit, dispatch }, payload) {
            try {
                // get dockets 
                await dispatch('getDockets', payload)

                // create array of buildings from dockets
                let buildings = state.dockets.map(docket => {
                    return docket.building
                })

                commit("setBuildings", buildings)
            } catch (e) {
                // TODO: error snackbar
                throw e;
            }
        },
        async getRooms({ state, commit, dispatch }, payload) {
            try {
                await dispatch('getDockets', payload)

                let rooms = state.dockets.map(docket => {
                    return docket.room
                })

                commit("setRooms", rooms)
            } catch (e) {
                // TODO: error snackbar
                throw e;
            }
        },

    }
}