<template>
  <div class="component-wrapper d-flex flex-column align-center">
    <div class="text-h6 text-center">
      Ενημέρωση Πολιτών για την πορεία των δικών
    </div>

    <v-img
      src="./../assets/DIKES-ICONS.svg"
      width="100%"
      max-width="350px"
    ></v-img>

    <v-btn class="primary" @click="$router.push({ path: '/search-trial' })">
      Συνεχεια
    </v-btn>

    <div class="text-body-1 text-center my-6">
      Στην υπηρεσία αυτή ενημερώνεστε για την πορεία των τρεχουσών δικαστικών
      υποθέσεων που διεξάγονται. Αναζητήστε την δίκη / υπόθεση που σας
      ενδιαφέρει μέσω αριθμού κτηρίου, αριθμού αίθουσας ή πινακίου. Η υπηρεσία
      μπορεί να χρησιμοποιηθεί από πολίτες και δικηγόρους χωρίς εγγραφή ή
      σύνδεση. Οι πληροφορίες ενημερώνονται σε πραγματικό χρόνο και τα στοιχεία
      και οι πληροφορίες είναι ανώνυμα κατ' εφαρμογή του Γενικού Κανονισμού
      Προστασίας Δεδομένων (GDPR).
    </div>

    <router-link to="/More-info">Περισσότερες πληροφορίες</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.component-wrapper {
  padding: 48px 20%;
}
</style>
